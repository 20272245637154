import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanmortoni/Code/Personal/qvsr/node_modules/gatsby-theme-docz/src/base/Layout.js";
import fullcycleURL from '../assets/qualtrics-1.jpg';
import halfcycleURL from '../assets/qualtrics-2.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "qualtrics"
    }}>{`Qualtrics`}</h1>
    <p>{`QVSR allows researchers to connect with `}<a parentName="p" {...{
        "href": "https://www.qualtrics.com/"
      }}>{`Qualtrics`}</a>{`. There are 2 approaches:`}</p>
    <h2 {...{
      "id": "approach-1-full-cycle"
    }}>{`Approach 1: Full cycle`}</h2>
    <ul>
      <li parentName="ul">{`Reasearcher has to create 2 surveys on Qualtrics.`}</li>
      <li parentName="ul">{`Respondent will start from Qualtrics survey #1 and at the end, Qualtrics will redirect respondent to QVSR survey that will redirect to Qualtrics survey #2.`}</li>
    </ul>
    <img src={fullcycleURL} style={{
      maxWidth: '750px',
      borderRadius: '8px'
    }} alt="Qualtrics full cycle" />
    <h2 {...{
      "id": "approach-2-half-cycle"
    }}>{`Approach 2: Half cycle`}</h2>
    <ul>
      <li parentName="ul">{`Researcher has to create 1 survey on Qualtrics.`}</li>
      <li parentName="ul">{`Respondent will start from QVSR and at the end it will redirect respondent to Qualtrics survey.`}</li>
    </ul>
    <img src={halfcycleURL} style={{
      maxWidth: '750px',
      borderRadius: '8px'
    }} alt="Qualtrics full cycle" />
    <h2 {...{
      "id": "difference-between-full-cycle-and-half-cycle"
    }}>{`Difference between Full cycle and Half cycle`}</h2>
    <p>{`The difference between the 2 approaches is that the `}<inlineCode parentName="p">{`full cycle`}</inlineCode>{` requires a `}<inlineCode parentName="p">{`userId`}</inlineCode>{` that comes from Qualtrics survey #1 to QVSR and then to Qualtrics survey #2. So that way we can identify the user through the full cycle.`}</p>
    <h2 {...{
      "id": "qvsr-to-qualtrics"
    }}>{`QVSR to Qualtrics`}</h2>
    <p>{`QVSR will pass down information via url params to Qualtrics, information such as:`}</p>
    <ul>
      <li parentName="ul">{`the vote for each question`}</li>
      <li parentName="ul">{`credits remainder`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`userId`}</inlineCode>{`, if there is no `}<inlineCode parentName="li">{`userId`}</inlineCode>{` it will be `}<inlineCode parentName="li">{`undefined`}</inlineCode></li>
    </ul>
    <p>{`Qualtrics will get that information as embedded data.`}</p>
    <h2 {...{
      "id": "creating-qualtrics-survey-1"
    }}>{`Creating Qualtrics survey #1`}</h2>
    <p>{`This is the survey that will bootstrap the full cycle, at the end of this survey respondent will be redirected to QVSR.`}</p>
    <ol>
      <li parentName="ol">{`if you want to pass down the `}<inlineCode parentName="li">{`userId`}</inlineCode>{` to QVSR you must create an element and set an embedded data in Qualtrics, for example:`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`userId = 1984
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Create an "end of survey" element in Qualtrics, and set to redirect respondent to a QVSR survey's URL, for example:`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`https://qvsr.io/survey/joPjWLVUFr8oGhHSKUCl/\${e://Field/userId}
`}</code></pre>
    <p>{`Note: This URL must be the URL generated in QVSR`}</p>
    <h2 {...{
      "id": "creating-qualtrics-survey-2"
    }}>{`Creating Qualtrics survey #2`}</h2>
    <p>{`This is the last piece of the Qualtrics and QVSR integration, it will get data from QVSR as embedded data.`}</p>
    <ol>
      <li parentName="ol">{`Must set embedded data in Qualtrics, for each questions researcher has created on QVSR, for example:`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`QV1
QV2
QV3
credrem
userId
`}</code></pre>
    <p>{`Notes:`}</p>
    <ul>
      <li parentName="ul">{`QV and L are prefix for question IDs that stand for Quadratic and Linear, it will start from QV1 or L1.`}</li>
      <li parentName="ul">{`When reasercher is creating a new survey, he can check questions IDs on QVSR and it needs to match and be addressed on Qualtrics.`}</li>
    </ul>
    <h2 {...{
      "id": "other"
    }}>{`Other`}</h2>
    <p>{`This integration could be scaled and improved as needed. However, in a long term we could to bring Qualtrics experience to our platform (dev note), avoiding the integration with Qualtrics.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      